import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';
import * as Globals from '../../globals';
import rhythm from '../utils/rhythm';
import Img from 'gatsby-image';
import TryItBanner from '../components/tryItBanner/tryItBanner';

const WhyItWorksPage = ({ data }) => {
  let headerBackgroundFluidImageStack = [
    data.contentfulSiteWhy.headerBackground.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo 
        title={data.contentfulSiteWhy.metaContent.title} 
        description={data.contentfulSiteWhy.metaContent.description}
      />
      <BackgroundImage
        fluid={headerBackgroundFluidImageStack}
        className={`jumbotron section-background pt-0`}
        Tag={`header`}
      >
        <Container style={{ zIndex: 1, position: 'relative' }}>
          <Row>
            <Col md={10} lg={8}>
              <h1>{data.contentfulSiteWhy.headerHeadline}</h1>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>

      <section className={`my-5`}>
        <Container>
          <Row className={`justify-content-center text-center`}>
            <Col md={10} lg={8}>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulSiteWhy.valueSummary.childMarkdownRemark
                      .html,
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <hr className={`m-0 w-50 mx-auto`} />

      <section className={`mt-5 mb-4`}>
        <Container>
          <Row className={`mb-4 justify-content-center`}>
            <Col md={10} lg={8}>
              <h2>{data.contentfulSiteWhy.areasHeadline}</h2>
            </Col>
          </Row>

          {data.contentfulSiteWhy.areas.map((area, index) => {
            return (
              <Row key={index}>
                <Col xs={4} md={2} className={`mx-auto mx-md-0`}>
                  <div
                    className={`mx-auto ml-md-auto`}
                    style={{ width: rhythm(2) }}
                  >
                    <img
                      src={area.icon.file.url}
                      key={area.icon.id}
                      alt={`alt text`}
                    />
                  </div>
                </Col>
                <Col xs={12} md={8}>
                  <div className={`mb-4 mt-3 mt-md-0 px-3 pl-md-0`}>
                    <h4 className={`text-muted`} key={area.headline.id}>
                      {area.headline}
                    </h4>
                    <div
                      key={area.content.id}
                      dangerouslySetInnerHTML={{
                        __html: area.content.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Container>
      </section>

      <hr className={`m-0 w-50 mx-auto`} />

      <section className={`my-5`}>
        <Container>
          <Row className={`px-md-5 align-items-center`}>
            <Col xs={8} md={4} className={`mx-auto mx-md-0`}>
              <Img
                fluid={data.contentfulSiteWhy.founderHeadshot.sizes}
                alt={`alt text`}
                className={`rounded-circle mb-3 mb-md-0`}
              />
            </Col>

            <Col xs={12} md={8}>
              <p
                className={`mb-0`}
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulSiteWhy.founderBio.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <TryItBanner />
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulSiteWhy {
      metaContent {
        title
        description
      }
      headerHeadline
      headerBackground {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      valueSummary {
        childMarkdownRemark {
          html
        }
      }
      areasHeadline
      areas {
        headline
        content {
          childMarkdownRemark {
            html
          }
        }
        icon {
          file {
            contentType
            url
            fileName
          }
        }
      }
      founderHeadshot {
        sizes {
          ...GatsbyContentfulSizes
        }
      }
      founderBio {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default WhyItWorksPage;
